import React from 'react';
import { Button, ListItem, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import './quillEditorStyles.css';
import theme from '../../theme/theme';
import { Box, Typography } from '@mui/material';

function QuizOption({
  optionKey,
  optionType,
  optionValue,
  isSelected,
  isDisabled,
  onOptionSelect,
  onToggleDisable,
  showCancelIcon,
}) {
  return (
    <ThemeProvider theme={theme}>
      <ListItem
        disablePadding
        sx={{
          display: 'flex',
          alignItems: 'center',
          
        }}
      >
        <Button
          variant="outlined"
          onClick={() => onOptionSelect(optionKey)}
          disabled={isDisabled}
          sx={{
            justifyContent: 'flex-start',
            textTransform: 'none',
            borderRadius: 3,
            width: '100%',
            ml: { xs: 5, lg: 'unset' },
            minHeight: { xs: '4vh', lg: '7vh' },
            height: 'auto',
            bgcolor: isSelected
              ? theme.palette.custom.default
              : isDisabled
                ? '#f0f0f0'
                : 'white',
            color: isSelected
              ? 'white'
              : isDisabled
                ? 'grey'
                : theme.palette.custom.dark,
            fontSize: '1rem',
            textAlign: 'left',
            textDecoration: isDisabled ? 'line-through' : 'none',
            borderWidth: 2,
            borderColor: isDisabled ? 'grey' : theme.palette.custom.dark,
            '&:hover': {
              bgcolor: isDisabled ? '#f0f0f0' : theme.palette.custom.dark,
              color: isDisabled ? 'grey' : 'white',
            },
            mt: '1.5vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {/* Option Key */}
            <Typography
              variant="body1"
              component="span"
              sx={{
                textAlign: 'left',
                fontWeight: isDisabled ? 'normal' : 'bold',
              }}
            >
              {optionKey}
            </Typography>

            {/* Option Value */}
            <Box
              sx={{
                ml: 2,
                textAlign: 'left',
                flexGrow: 1,
              }}
            >
              {optionType === 'image' ? (
                <img
                  src={optionValue}
                  alt={`Option ${optionKey}`}
                  style={{ maxWidth: '100%' }}
                />
              ) : (
                <Typography
                  variant="body2"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: optionValue }}
                  sx={{
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                />
              )}
            </Box>
          </Box>
        </Button>
        {showCancelIcon && (
          <IconButton
            onClick={() => onToggleDisable(optionKey)}
            sx={{ color: isDisabled ? 'grey' : 'black', ml: 2, mt: '1.5vh' }}
          >
            <CancelIcon />
          </IconButton>
        )}
      </ListItem>
    </ThemeProvider>
  );
}

export default QuizOption;