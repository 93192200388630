// src/components/MainPage_components/PaymentPage/PaymentPage.js
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Button, Card, CardContent, Grid, Stack, Divider, ThemeProvider } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../actions/authActions';
import { useApi } from '../../../contexts/Apicontext';
import axios from 'axios';
import { isMobile } from '../../../util/util';
import theme from '../../../theme/theme';


const PaymentPage = () => {
    const { step, orderId } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { course } = location.state || {};
    const token = localStorage.getItem('token');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [showPaymentUI, setShowPaymentUI] = useState(true);
    const [address, setAddress] = useState({
        country: '한국',
        Name: jwtDecode(token).name,
        email: '',
        phoneNumber: '',
    });
    let orderNumber = null;
    let inicisFormStatus = null;
    const apiBaseUrl = useApi();

    const handleLogout = () => {
        if (window.confirm('로그아웃 하시겠습니까?')) {
            dispatch(logoutUser());
            localStorage.removeItem('token');
            navigate('/');
        }
    };

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setAddress((prevState) => ({
                ...prevState,
                Name: decoded.name || '',
                email: decoded.email || '',
                phoneNumber: decoded.phoneNumber || '',
            }));
        }
    }, [token]);

    if (!course) {
        return (
            <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="h6">결제할 수업 정보가 없습니다.</Typography>
            </Box>
        );
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddress((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const saveAddress = () => {
        console.log('Saved Address:', address);
        alert('정보가 저장되었습니다.');
    };

    const onOrderRequest = () => {
        if (isMobile()) return mobileOrder();
        else return webOrder();
    };

    const newOrder = async () => {
        try {
            console.log(course);
            const response = await fetch(`${apiBaseUrl}/api/request/neworder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    userID: jwtDecode(token).userID,
                    paymentMethod: selectedPaymentMethod,
                    classID: course.classID,
                    amount: course.price,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('New Order:', data);

            if (data.status === 'success') {
                return data;
            } else {
                throw new Error('Order creation failed');
            }
        } catch (error) {
            console.error('Error creating new order:', error);
            alert('주문 생성 중 문제가 발생했습니다.');
        }
    };

    const webOrder = async () => {
        try {
            // 새 주문 생성
            const info = await newOrder();
            const { status, data } = info;

            if (status === 'success') {
                orderNumber = data;

                // HTML 응답을 받도록 responseType을 'text'로 설정
                axios.get(`${apiBaseUrl}/api/request/renderform`, {
                    params: { orderId: data }
                })
                    .then((response) => {
                        const { data: info } = response;
                        const { status, data } = info;
                        const form = document.createElement('form');
                        form.method = 'post';
                        form.acceptCharset = 'UTF-8';
                        form.hidden = true;
                        form.id = 'pay_form';

                        for (let o in data) {
                            const input = document.createElement('input');
                            input.name = o;
                            input.value = data[o];
                            input.hidden = true;
                            form.appendChild(input);
                        }

                        document.querySelector('#shop-page').appendChild(form);
                        window.INIStdPay.pay('pay_form');

                        inicisFormStatus = setInterval(checkInicisFormStatus, 1000);
                    })
                    .catch((error) => {
                        console.error('HTML 로드 실패:', error);
                        alert('결제 요청에 실패했습니다.');
                    });
            } else {
                alert('주문 생성에 실패했습니다.');
            }
        } catch (error) {
            console.error('Web order 처리 중 오류 발생:', error);
            alert('결제 요청 중 문제가 발생했습니다.');
        }
    };

    const mobileOrder = async () => {
        try {
            const { data: info } = await newOrder();
            const { status, data } = info;
            console.log('New Order:', status, data);
            // 모바일 결제 관련 로직 구현 (필요 시)
        } catch (error) {
            console.error('Error during payment:', error);
            alert('결제 요청 중 오류가 발생했습니다.');
        }
    };

    // 결제 팝업이 닫힌 상태를 확인하고, 라우터를 통해 별도 경로로 이동
    const checkInicisFormStatus = () => {
        const node = document.querySelector('.inipay_modal-backdrop');
        if (node) return true;
        else {
            const form_node = document.querySelector('#pay_form');
            if (form_node) form_node.remove();

            fetchOrderInfo();
            return false;
        }
    };

    const fetchOrderInfo = async () => {
        clearInterval(inicisFormStatus);
        console.log('Fetching order info:', orderNumber, orderId);
        if (!orderNumber && !orderId) {
            console.error('No order number found');
            navigate('/payment/fail');
        } else {
            console.log('Redirecting to order result page:', orderNumber || orderId);
            navigate(`/payment/result/${orderNumber || orderId}`);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div>
                {showPaymentUI && (
                    <div id='shop-page'>
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            {/* 기존 PaymentPage UI */}
                            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <Box sx={{ p: 3, width: '80%', minWidth: '600px' }}>
                                    {/* Step 1: 로그인 섹션 */}
                                    <Box
                                        sx={{
                                            border: '1px solid #d9e1e7',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            backgroundColor: '#d9e1e7',
                                            marginBottom: '24px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '16px' }}>
                                                1
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                로그인 아이디: {address.Name}
                                            </Typography>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#3d3d3d',
                                                color: '#fff',
                                                fontSize: '0.875rem',
                                                '&:hover': {
                                                    backgroundColor: '#2d2d2d',
                                                },
                                            }}
                                            onClick={handleLogout}
                                        >
                                            다른 아이디로 로그인
                                        </Button>
                                    </Box>

                                    {/* Step 2: 결제 정보 입력 섹션 */}
                                    <Box
                                        sx={{
                                            border: '1px solid #d9e1e7',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            backgroundColor: '#d9e1e7',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 2 }}>
                                            <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '16px' }}>
                                                2
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                결제 정보 입력
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="이름"
                                                    name="name"
                                                    value={address.Name}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="이메일"
                                                    name="email"
                                                    value={address.email}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    label="전화번호"
                                                    name="phone"
                                                    value={address.phoneNumber || ''}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ textAlign: 'right', mt: 2 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    backgroundColor: '#3d3d3d',
                                                    color: '#fff',
                                                    fontSize: '0.875rem',
                                                    '&:hover': {
                                                        backgroundColor: '#2d2d2d',
                                                    },
                                                }}
                                                onClick={saveAddress}
                                            >
                                                정보 저장
                                            </Button>
                                        </Box>
                                    </Box>

                                    {/* Step 3: 결제 정보 확인 섹션 */}
                                    <Box
                                        sx={{
                                            border: '1px solid #d9e1e7',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            backgroundColor: '#d9e1e7',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 2 }}>
                                            <Typography variant="h6" fontWeight="bold" sx={{ marginRight: '16px' }}>
                                                3
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                결제 정보 확인
                                            </Typography>
                                        </Box>

                                        <Card variant="outlined" sx={{ mb: 3 }}>
                                            <CardContent>
                                                <Typography variant="subtitle1">
                                                    <strong>수업명:</strong> {course.className}
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    <strong>수업료:</strong>{' '}
                                                    {Math.round(course.price).toLocaleString()} KRW
                                                </Typography>
                                            </CardContent>
                                        </Card>

                                        <Stack spacing={2} direction="row" justifyContent="center" sx={{ mb: 3 }}>
                                            <Button
                                                onClick={() => setSelectedPaymentMethod('Card')}
                                                sx={{
                                                    flex: 1,
                                                    textTransform: 'none',
                                                    fontWeight: selectedPaymentMethod === 'Card' ? 'bold' : 'normal',
                                                    backgroundColor: selectedPaymentMethod === 'Card' ? theme.palette.grey[800] : 'transparent',
                                                    color: selectedPaymentMethod === 'Card' ? theme.palette.common.white : theme.palette.text.primary,
                                                    border: selectedPaymentMethod === 'Card' ? 'none' : `1px solid ${theme.palette.grey[400]}`,
                                                    '&:hover': {
                                                        backgroundColor: selectedPaymentMethod === 'Card' ? theme.palette.grey[900] : theme.palette.grey[200],
                                                    },
                                                    borderRadius: 2,
                                                    py: 1,
                                                }}
                                            >
                                                신용카드
                                            </Button>
                                            <Button
                                                onClick={() => setSelectedPaymentMethod('VBank')}
                                                sx={{
                                                    flex: 1,
                                                    textTransform: 'none',
                                                    fontWeight: selectedPaymentMethod === 'VBank' ? 'bold' : 'normal',
                                                    backgroundColor: selectedPaymentMethod === 'VBank' ? theme.palette.grey[800] : 'transparent',
                                                    color: selectedPaymentMethod === 'VBank' ? theme.palette.common.white : theme.palette.text.primary,
                                                    border: selectedPaymentMethod === 'VBank' ? 'none' : `1px solid ${theme.palette.grey[400]}`,
                                                    '&:hover': {
                                                        backgroundColor: selectedPaymentMethod === 'VBank' ? theme.palette.grey[900] : theme.palette.grey[200],
                                                    },
                                                    borderRadius: 2,
                                                    py: 1,
                                                }}
                                            >
                                                가상계좌
                                            </Button>
                                        </Stack>
                                        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                            * "주문완료" 버튼을 누르면 결제 페이지로 이동합니다.
                                        </Typography>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            fullWidth
                                            onClick={() => onOrderRequest('completeOrder')}
                                        >
                                            주문완료
                                        </Button>
                                    </Box>
                                </Box>
                                {/* 우측 주문 요약 영역 */}
                                <Box
                                    sx={{
                                        width: '20%',
                                        borderLeft: '1px solid #d9e1e7',
                                        paddingLeft: '16px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minWidth: '200px'
                                    }}
                                >
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                                        주문 요약
                                    </Typography>
                                    <Divider sx={{ mb: 2, borderWidth: '1px' }} />
                                    <Box sx={{ display: 'flex', mb: 2, flexDirection: 'column' }}>
                                        <Box>
                                            <Typography variant="h6" fontWeight='bold'>
                                                수업
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body1">
                                                {course.className}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                        <Typography variant="h6">
                                            <strong>수업료:</strong>
                                        </Typography>
                                        <Typography variant="h6">
                                            {Math.round(course.price).toLocaleString()} 원
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                )}
            </div>
        </ThemeProvider>
    );
};

export default PaymentPage;