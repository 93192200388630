import React, { useState, useEffect } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
    IconButton, Typography, Button, Dialog, DialogTitle, DialogContent, 
    DialogContentText, TextField, DialogActions, MenuItem, Select 
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApi } from '../../contexts/Apicontext';

const LectureQuizzesPage = () => {
    const location = useLocation();
    const { lectureID } = location.state || {};
    const [quizzes, setQuizzes] = useState([]);
    const navigate = useNavigate();
    const [existingQuizzes, setExistingQuizzes] = useState([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openSelectDialog, setOpenSelectDialog] = useState(false);
    const [newQuiz, setNewQuiz] = useState({ title: '', description: '' });
    const [selectedQuizId, setSelectedQuizId] = useState('');
    const apiBaseUrl = useApi();

    useEffect(() => {
        fetchQuizzes();
        fetchExistingQuizzes();
    }, [lectureID]);

    const fetchQuizzes = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/quizzes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setQuizzes(data);
    };

    const fetchExistingQuizzes = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizzes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setExistingQuizzes(data);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewQuiz({ title: '', description: '' });
    };

    const handleAddQuiz = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/quizzes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newQuiz)
        });

        if (response.ok) {
            fetchQuizzes();
            handleCloseAddDialog();
        } else {
            alert('Failed to add new quiz.');
        }
    };

    const handleOpenSelectDialog = () => {
        setOpenSelectDialog(true);
    };

    const handleCloseSelectDialog = () => {
        setOpenSelectDialog(false);
        setSelectedQuizId('');
    };

    const handleAddExistingQuiz = async () => {
        if (!selectedQuizId) {
            alert('Please select a quiz first.');
            return;
        }

        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/existing-quizzes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ quizID: selectedQuizId })
        });

        if (response.ok) {
            fetchQuizzes();
            handleCloseSelectDialog();
        } else {
            alert('Failed to add existing quiz.');
        }
    };

    const handleDeleteQuiz = async (quizID, event) => {
        event.stopPropagation();
        const result = window.confirm('Are you sure you want to delete this quiz from the lecture?');

        if (result) {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/lectures/${lectureID}/quizzes/${quizID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                fetchQuizzes();
            } else {
                alert('Failed to delete quiz.');
            }
        }
    };

    // 클릭 시 해당 퀴즈의 quizID로 /admin/quizzes/{quizID}/questions로 이동
    const handleQuizClick = (quizID) => {
        navigate(`/admin/quizzes/${quizID}/questions`, { state: { quizID } });
    };

    return (
        <div className='w-full'>
            <IconButton onClick={() => navigate(-1)} color="primary">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
                Quizzes for Lecture {lectureID}
            </Typography>
            <Button 
                variant="contained" 
                color="primary" 
                startIcon={<AddIcon />} 
                sx={{ mb: 2 }} 
                onClick={handleOpenAddDialog}
            >
                Add New Quiz
            </Button>
            <Button 
                variant="contained" 
                color="secondary" 
                startIcon={<AddIcon />} 
                sx={{ mb: 2, ml: 2 }} 
                onClick={handleOpenSelectDialog}
            >
                Add Existing Quiz
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Quiz ID</TableCell>
                            <TableCell>Quiz Title</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quizzes.map((quiz) => (
                            <TableRow 
                              key={quiz.quizID}
                              hover
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleQuizClick(quiz.quizID)}
                            >
                                <TableCell>{quiz.quizID}</TableCell>
                                <TableCell>{quiz.title}</TableCell>
                                <TableCell>
                                    <IconButton 
                                      onClick={(e) => handleDeleteQuiz(quiz.quizID, e)} 
                                      color="secondary"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Add New Quiz Dialog */}
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>Add New Quiz</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new quiz.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label="Title"
                        value={newQuiz.title}
                        onChange={(e) => setNewQuiz({ ...newQuiz, title: e.target.value })}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="description"
                        label="Description"
                        value={newQuiz.description}
                        onChange={(e) => setNewQuiz({ ...newQuiz, description: e.target.value })}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddQuiz} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Select Existing Quiz Dialog */}
            <Dialog open={openSelectDialog} onClose={handleCloseSelectDialog} fullWidth maxWidth="sm">
                <DialogTitle>Select Existing Quiz</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Choose an existing quiz to add to this lecture.
                    </DialogContentText>
                    <Select
                        name="existingQuiz"
                        value={selectedQuizId}
                        onChange={(e) => setSelectedQuizId(e.target.value)}
                        fullWidth
                    >
                        {existingQuizzes.map((quiz) => (
                            <MenuItem key={quiz.quizID} value={quiz.quizID}>
                                {quiz.title}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSelectDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddExistingQuiz} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LectureQuizzesPage;