import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess, logoutUser } from './actions/authActions';
import {jwtDecode} from 'jwt-decode';
import { ApiProvider } from './contexts/Apicontext';
import './App.css';

// Main Pages
import MainPage from './components/MainPage_components/MainPage';
import LoginPage from './components/MainPage_components/LoginPage';
import SignUpPage from './components/MainPage_components/SignUpPage';

// Student Pages
import StudentMainPage from './components/StudentMain_Page_components/StudentMainPage';

// Admin Pages
import AdminMainPage from './components/Admin_components/AdminMainPage';
import UserAdminPage from './components/Admin_components/UserAdminPage';
import ClassAdminPage from './components/Admin_components/ClassAdminPage';
import LectureAdminPage from './components/Admin_components/LectureAdminPage';
import QuizAdminPage from './components/Admin_components/QuizAdminPage';
import TagAdminPage from './components/Admin_components/TagAdminPage';
import AIAdminPage_MATH from './components/Admin_components/AI_MATH/AIAdminPage_MATH';
import MathGeneratedAdminPage from './components/Admin_components/AI_MATH/MathGeneratedAdminPage';
import AIAdminPage_ENG from './components/Admin_components/AI_ENG/AIAdminPage_ENG';
import EnglishGeneratedAdminPage from './components/Admin_components/AI_ENG/EnglishGeneratedAdminPage';

// Teacher Pages
import TeacherMainPage from './components/TeacherMainPage';

// Lecture Pages
import LectureList from './components/LecturePage_components/LectureList';
import QuizQuestionDetail from './components/LecturePage_components/QuizQuestionDetail';
import QuizResults from './components/LecturePage_components/QuizResults';
import BundleMockTestResults from './components/LecturePage_components/BundleMockTestResults';

// Mock Test Pages
import MockTestPage from './components/MockTest_components/MockTestPage';
import MockTestEndPage from './components/MockTest_components/MockTestEndPage';
import BundleMockTestPage1 from './components/MockTest_components/BundleMockTestPage1';
import BundleMockTestPage2 from './components/MockTest_components/BundleMockTestPage2';
import BundleMockTestEndPage from './components/MockTest_components/BundleMockTestEndPage';

// Other Components
import PrivateRoute from './components/PrivateRoute';
import NotImplementedPage from './components/NotImplementedPage';

function App() {
  const dispatch = useDispatch();

  

  useEffect(() => {
    // Check for token in local storage
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // Check if token is expired
        if (decoded.exp < currentTime) {
          dispatch(logoutUser());
        } else {
          dispatch(loginSuccess(decoded));
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        dispatch(logoutUser());
      }
    }
  }, [dispatch]);


  return (
    <ApiProvider>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<MainPage currentRoute="home" />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />

          {/* Private Routes */}
          <Route path="/myprofile" element={<PrivateRoute><MainPage currentRoute="myprofile" /></PrivateRoute>} />
          <Route path="/courses" element={<MainPage currentRoute="courses" />} />
          <Route path="/board" element={<MainPage currentRoute="board" />} />
          <Route path="/board/announcement" element={<MainPage currentRoute="board" />} />
          <Route path="/board/:boardID/post/:postID" element={<MainPage currentRoute="board/post" />} />
          <Route path="/payment" element={<PrivateRoute><MainPage currentRoute="payment" /></PrivateRoute>} />
          <Route path="/payment/close" element={<PrivateRoute><MainPage currentRoute="payment close" /></PrivateRoute>} />
          <Route path="/payment/fail" element={<PrivateRoute><MainPage currentRoute="payment fail" /></PrivateRoute>} />
          <Route path="/payment/success" element={<PrivateRoute><MainPage currentRoute="payment success" /></PrivateRoute>} />
          <Route path="/student" element={<PrivateRoute><StudentMainPage /></PrivateRoute>} />
          <Route path="/myprogress" element={<PrivateRoute><MainPage currentRoute="myprogress" /></PrivateRoute>} />
          <Route path="/teacher" element={<PrivateRoute><TeacherMainPage /></PrivateRoute>} />
          <Route path="/classes/:classID/lectures/*" element={<PrivateRoute><LectureList /></PrivateRoute>} />
          <Route path="/:classID/lectures/:lectureID/quizzes/:quizID/results" element={<PrivateRoute><QuizResults /></PrivateRoute>} />
          <Route path="/:classID/lectures/:lectureID/quizzes/:quizID/questions/:order" element={<PrivateRoute><QuizQuestionDetail /></PrivateRoute>} />
          <Route path="/lectures/:lectureID/mock-tests/:attemptID/start" element={<PrivateRoute><MockTestPage /></PrivateRoute>} />
          <Route path="/lectures/:lectureID/bundle-mock-tests/:attemptID/start" element={<PrivateRoute><BundleMockTestPage1 /></PrivateRoute>} />
          <Route path="/lectures/:lectureID/bundle-mock-tests/:attemptID/second" element={<PrivateRoute><BundleMockTestPage2 /></PrivateRoute>} />
          <Route path="/lectures/:lectureID/mock-tests/:attemptID/end" element={<PrivateRoute><MockTestEndPage /></PrivateRoute>} />
          <Route path="/lectures/:lectureID/bundle-mock-tests/:attemptID/end" element={<PrivateRoute><BundleMockTestEndPage /></PrivateRoute>} />
          <Route path="/:classID/lectures/:lectureID/bundlemocktests/:bundleID/results" element={<PrivateRoute><BundleMockTestResults/></PrivateRoute>}/>

          {/* Admin Routes */}
          <Route path="/admin" element={<PrivateRoute><AdminMainPage /></PrivateRoute>} />
          <Route path="/admin/users/*" element={<PrivateRoute><UserAdminPage /></PrivateRoute>} />
          <Route path="/admin/classes/*" element={<PrivateRoute><ClassAdminPage /></PrivateRoute>} />
          <Route path="/admin/lectures/*" element={<PrivateRoute><LectureAdminPage /></PrivateRoute>} />
          <Route path="/admin/quizzes/*" element={<PrivateRoute><QuizAdminPage /></PrivateRoute>} />
          <Route path="/admin/tags/*" element={<PrivateRoute><TagAdminPage /></PrivateRoute>} />
          <Route path="/admin/MATH_AI" element={<PrivateRoute><AIAdminPage_MATH /></PrivateRoute>} />
          <Route path="/admin/ENG_AI" element={<PrivateRoute><AIAdminPage_ENG /></PrivateRoute>} />
          <Route path="/admin/MATH_AI_LIST/*" element={<PrivateRoute><MathGeneratedAdminPage /></PrivateRoute>} />
          <Route path="/admin/ENG_AI_LIST/*" element={<PrivateRoute><EnglishGeneratedAdminPage /></PrivateRoute>} />

          {/* Fallback Route */}
          <Route path="/not-implemented" element={<NotImplementedPage />} />
          <Route path="*" element={<Navigate to="/not-implemented" />} />
        </Routes>
      </BrowserRouter>
    </ApiProvider>
  );
}

export default App;
