import React, { useEffect } from "react";
import { Container, Card, CardContent, Typography, Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// URL의 쿼리스트링을 파싱하는 커스텀 훅
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PaySuccess = () => {
  const query = useQuery();
  const navigate = useNavigate();

  // 쿼리 파라미터로 전달된 값들 추출
  const resultCode = query.get("resultCode");
  const resultMsg = query.get("resultMsg");
  const tid = query.get("tid");
  const MOID = query.get("orderNumber");
  const TotPrice = query.get("TotPrice");
  const goodName = query.get("goodName");
  const applDate = query.get("applDate");
  const applTime = query.get("applTime");
  const buyerName = query.get("buyerName");
  const buyerEmail = query.get("buyerEmail");
  const buyerTel = query.get("buyerTel");

  // 부모 창 또는 현재 창에서 성공 URL로 이동하는 함수
  const redirectSuccessAndClose = () => {
    // 성공 URL 구성 (쿼리 파라미터 포함)
    let successUrl = "http://localhost:3000/payment/success";
    successUrl += "?resultCode=" + encodeURIComponent(resultCode || "");
    successUrl += "&resultMsg=" + encodeURIComponent(resultMsg || "");
    successUrl += "&tid=" + encodeURIComponent(tid || "");
    successUrl += "&orderNumber=" + encodeURIComponent(MOID || "");
    successUrl += "&TotPrice=" + encodeURIComponent(TotPrice || "");
    successUrl += "&goodName=" + encodeURIComponent(goodName || "");
    successUrl += "&applDate=" + encodeURIComponent(applDate || "");
    successUrl += "&applTime=" + encodeURIComponent(applTime || "");
    successUrl += "&buyerName=" + encodeURIComponent(buyerName || "");
    successUrl += "&buyerEmail=" + encodeURIComponent(buyerEmail || "");
    successUrl += "&buyerTel=" + encodeURIComponent(buyerTel || "");

    // 부모 창이 존재하면 부모 창의 URL을 변경하고 창을 닫음
    if (window.opener && !window.opener.closed) {
      window.opener.location.href = successUrl;
      window.close();
    } else {
      // 부모 창이 없으면 현재 창에서 리디렉션
      window.location.href = successUrl;
    }
  };

  // 만약 페이지가 팝업으로 열렸고, resultCode가 '0000'이면 자동으로 리디렉션 (옵션)
  useEffect(() => {
    if (resultCode === "0000") {
      // 자동 리디렉션을 원하지 않으면 주석 처리
      // redirectSuccessAndClose();
    }
  }, [resultCode]);

  const Close = () => {
    navigate('/'); // 홈으로 이동
  };

  return (
    <Container maxWidth="sm">
      <Card sx={{ mt: 8, boxShadow: 3, borderRadius: 2 }}>
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            align="center"
            gutterBottom
            color="success.main"
          >
            결제 성공
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            결제가 성공적으로 처리되었습니다. 아래 정보를 확인하시고, 창 닫기를 누르시면 성공 페이지로 이동합니다.
          </Typography>
          <Box sx={{ textAlign: "left", mb: 2 }}>
            {resultCode && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>결과 코드:</strong> {resultCode}
              </Typography>
            )}
            {resultMsg && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>메시지:</strong> {resultMsg}
              </Typography>
            )}
            {tid && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>TID:</strong> {tid}
              </Typography>
            )}
            {MOID && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>주문번호:</strong> {MOID}
              </Typography>
            )}
            {TotPrice && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>총 금액:</strong> {TotPrice}
              </Typography>
            )}
            {goodName && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>상품명:</strong> {goodName}
              </Typography>
            )}
            {applDate && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>결제 날짜:</strong> {applDate}
              </Typography>
            )}
            {applTime && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>결제 시간:</strong> {applTime}
              </Typography>
            )}
            {buyerName && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>구매자 이름:</strong> {buyerName}
              </Typography>
            )}
            {buyerEmail && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>구매자 이메일:</strong> {buyerEmail}
              </Typography>
            )}
            {buyerTel && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>구매자 연락처:</strong> {buyerTel}
              </Typography>
            )}
          </Box>
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={Close}
              sx={{
                textTransform: "none",
                borderRadius: 2,
                px: 3,
                py: 1,
              }}
            >
              창 닫기
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PaySuccess;