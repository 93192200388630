import React from "react";
import { Box, Typography, Breadcrumbs, Link } from "@mui/material";

const CurrentPath = ({ currentPath, onPathClick }) => {
  // 경로를 "/"로 분리
  const paths = currentPath.split("/").filter((path) => path);

  return (
    <Box sx={{ display: "flex", alignItems: "center", padding: "8px 16px" }}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {/* 경로의 각 부분을 렌더링 */}
        {paths.map((path, index) => {
          const isLast = index === paths.length - 1;
          return isLast ? (
            <Typography
              key={index}
              sx={{
                fontWeight: "bold",
                color: "#555",
                textTransform: "capitalize",
              }}
            >
              {path}
            </Typography>
          ) : (
            <Link
              key={index}
              underline="hover"
              color="inherit"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const newPath = paths.slice(0, index + 1).join("/");
                onPathClick(newPath);
              }}
              sx={{
                textTransform: "capitalize",
                fontWeight: "bold",
                color: "#555",
                ":hover": { color: "#1976d2" },
              }}
            >
              {path}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default CurrentPath;