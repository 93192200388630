import React from "react";
import { Container, Card, CardContent, Typography, Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

// URL의 쿼리스트링을 파싱하는 커스텀 훅
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PayFail = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const resultCode = query.get("resultCode");
  const resultMsg = query.get("resultMsg");
  const tid = query.get("tid");
  const MOID = query.get("MOID");
  const TotPrice = query.get("TotPrice");
  const goodName = query.get("goodName");
  const applDate = query.get("applDate");
  const applTime = query.get("applTime");

  const handleReturnHome = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="sm">
      <Card sx={{ mt: 8, boxShadow: 3, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" component="h2" align="center" gutterBottom color="error">
            결제 실패
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            결제에 실패하였습니다. 아래 정보를 확인하시고, 다시 시도하거나 고객센터에 문의해 주세요.
          </Typography>
          <Box sx={{ textAlign: "left", mb: 2 }}>
            {resultCode && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>결과 코드:</strong> {resultCode}
              </Typography>
            )}
            {resultMsg && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>메시지:</strong> {resultMsg}
              </Typography>
            )}
            {tid && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>TID:</strong> {tid}
              </Typography>
            )}
            {MOID && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>주문번호 (MOID):</strong> {MOID}
              </Typography>
            )}
            {TotPrice && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>총 금액:</strong> {TotPrice}
              </Typography>
            )}
            {goodName && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>상품명:</strong> {goodName}
              </Typography>
            )}
            {applDate && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>결제 날짜:</strong> {applDate}
              </Typography>
            )}
            {applTime && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>결제 시간:</strong> {applTime}
              </Typography>
            )}
          </Box>
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Button variant="contained" color="primary" onClick={handleReturnHome}>
              홈으로 돌아가기
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PayFail;