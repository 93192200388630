import React, { useState } from 'react';
import { Box, Toolbar, Container } from '@mui/material';
import AdminHeader from './AdminHeader';
import AdminMenuDrawer from './AdminMenuDrawer';
import LecturesTable from './LecturesTable';
import LectureClassesPage from './LectureClassesPage';
import LectureQuizzesPage from './LectureQuizzesPage';
import { Routes, Route, useNavigate } from 'react-router-dom';
import QuizBundlesPage from './QuizBundlesPage';

const drawerWidth = 240;

const LectureAdminPage = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handlePageChange = (page, state) => {
        navigate(`/admin/${page}`, { state });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader handleDrawerToggle={handleDrawerToggle} />
            <AdminMenuDrawer handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                    width: { sm: '100%' } // Calculate width excluding drawer
                }}
            >
                <Toolbar />
                <Box sx={{ width: '100%' }}>
                    <Routes>
                        <Route path="/" element={<LecturesTable onPageChange={handlePageChange} />} />
                        <Route path="/:lectureID/classes" element={<LectureClassesPage onPageChange={handlePageChange}/>} />
                        <Route path="/:lectureID/quizzes" element={<LectureQuizzesPage onPageChange={handlePageChange}/>} />
                        <Route path='/:lectureID/quizbundles' element={<QuizBundlesPage />} />
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

export default LectureAdminPage;
