import React, { useState, useEffect, memo } from 'react';
import {
    Paper, Typography, IconButton, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, MenuItem, Select, Autocomplete
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AWS from 'aws-sdk';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quillEditorStyles.css';
import { useApi } from '../../contexts/Apicontext';
import { useLocation, useNavigate } from 'react-router-dom';
import katex from "katex";
import "katex/dist/katex.min.css";
window.katex = katex;

// AWS S3 설정
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

const uploadFileToS3_option = (file, quizID, order, optionName) => {
    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `${quizID}/${order}/option-${optionName}-${file.name}`, // 옵션 이미지용 파일 이름
        Body: file
    };
    return s3.upload(params).promise();
};

const uploadFileToS3_content = (file, quizID, order, imageIndex) => {
    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `${quizID}/${order}/content-${imageIndex}-${file.name}`, // S3에 저장될 파일 이름
        Body: file
    };
    const uploadPromise = s3.upload(params).promise();
    return uploadPromise;
};

const QuizQuestionDetailsPage = ({ onPageChange }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { quizID, questionID, questions, showImageOnly } = location.state || {};
    const [question, setQuestion] = useState(null);
    const [editQuestion, setEditQuestion] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
    const [uploadedImageCount, setUploadedImageCount] = useState(0);
    const [difficultyError, setDifficultyError] = useState(false);
    const [tags, setTags] = useState([]);
    const [detailedTags, setDetailedTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedDetailedTag, setSelectedDetailedTag] = useState(null);
    const apiBaseUrl = useApi();
    const [originalQuestion, setOriginalQuestion] = useState(null);


    useEffect(() => {
        fetchQuestionDetails();
        fetchTags();
    }, [questionID]);


    const fetchQuestionDetails = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/questions/${questionID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();

        // 로컬 시간으로 변환
        if (data.lastModifiedAt) {
            data.lastModifiedAt = convertToLocalTime(data.lastModifiedAt);
        }
        setQuestion(data);
        setOriginalQuestion(data); // 원본 데이터 저장
        setUploadedImageCount(data.content.filter(item => item.type === 'image').length);
        setSelectedTag(data.tag);
        setSelectedDetailedTag(data.detailedTag);
    };

    // S3 객체 삭제 함수: S3 URL에서 Key를 추출하여 삭제
    const deleteS3Object = async (objectUrl) => {
        try {
            const urlObj = new URL(objectUrl);
            // decodeURIComponent를 사용하여 인코딩된 키를 복원
            let key = decodeURIComponent(urlObj.pathname);
            if (key.startsWith('/')) {
                key = key.substring(1);
            }
            const params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: key
            };
            await s3.deleteObject(params).promise();
            console.log("Deleted S3 object with key:", key);
        } catch (error) {
            console.error("Failed to delete S3 object:", error);
        }
    };

    const fetchTags = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setTags(data);
    };

    const fetchDetailedTags = async (tagID) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/tags/${tagID}/detailed`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setDetailedTags(data);
    };

    const handleEditClick = () => {
        // 깊은 복사를 통해 editQuestion을 설정하여 originalQuestion에 영향 주지 않도록 함
        setEditQuestion(JSON.parse(JSON.stringify(question)));
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setEditQuestion(null);
        setUploadedFileUrl(null);
    };
    const handleSaveClick = async () => {
        if (!editQuestion.difficulty) {
            setDifficultyError(true);
            return;
        }
        const token = localStorage.getItem('token');

        // 1. S3 삭제 처리: 원본 이미지 링크와 편집 후 이미지 링크를 비교
        if (originalQuestion) {
            // Content 비교: 기존의 content 배열의 각 항목이 편집 후 배열에 존재하는지 확인 (type과 value 비교)
            console.log('originalQuestion:', originalQuestion);
            originalQuestion.content.forEach(async (origItem) => {
                if (origItem.type === 'image' && origItem.value) {
                    console.log('origItem:', origItem);
                    const exists = editQuestion.content.some(
                        (item) => item.type === 'image' && item.value === origItem.value
                    );
                    if (!exists) {
                        await deleteS3Object(origItem.value);
                    }
                }
            });

            // Options 비교: 기존 옵션의 각 이미지 항목이 편집 후 배열에 존재하는지 확인 (type, value, 그리고 option name 비교)
            originalQuestion.options.forEach(async (origOpt) => {
                if (origOpt.type === 'image' && origOpt.value) {
                    const exists = editQuestion.options.some(
                        (item) =>
                            item.type === 'image' &&
                            item.value === origOpt.value &&
                            item.name === origOpt.name
                    );
                    if (!exists) {
                        await deleteS3Object(origOpt.value);
                    }
                }
            });
        }

        // 2. 저장 요청: PUT 요청으로 질문 정보를 업데이트
        const response = await fetch(`${apiBaseUrl}/admin/questions/${questionID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...editQuestion,
                tag: selectedTag,
                detailedTag: selectedDetailedTag,
                memo: editQuestion?.modificationNote || ''
            })
        });

        if (response.ok) {
            alert('Question information has been updated.');
            handleCloseEditDialog();
            fetchQuestionDetails();
        } else {
            alert('Failed to update question information.');
        }
    };

    const handleUploadClick = async (optionName, optionIndex) => {
        if (selectedFile) {
            try {
                const uploadResult = await uploadFileToS3_option(selectedFile, quizID, editQuestion.order, optionName);
                // handleOptionChange를 사용하여 editQuestion.options의 해당 인덱스의 value를 업로드된 URL로 업데이트
                handleOptionChange(optionIndex, 'value', uploadResult.Location);
                alert('Option image has been uploaded to S3.');
            } catch (error) {
                alert('Failed to upload image.');
            }
        } else {
            alert('No file selected.');
        }
    };

    const handleUploadClick_content = async (contentIndex) => {
        if (selectedFile) {
            try {
                // content 이미지 업로드 시, content의 index(contentIndex)를 반영하여 파일 키 생성
                const uploadResult = await uploadFileToS3_content(
                    selectedFile,
                    quizID,
                    editQuestion.order,
                    contentIndex  // uploadedImageCount 대신 contentIndex 사용
                );
                // 업로드 결과의 URL을 해당 content 항목의 value로 업데이트
                const updatedContent = [...editQuestion.content];
                updatedContent[contentIndex] = {
                    ...updatedContent[contentIndex],
                    value: uploadResult.Location
                };
                setEditQuestion({ ...editQuestion, content: updatedContent });
                alert('Content image has been uploaded to S3.');
            } catch (error) {
                alert('Failed to upload content image.');
            }
        } else {
            alert('No file selected.');
        }
    };

    const handleDeleteClick = async () => {
        const result = window.confirm('Are you sure you want to delete this question?');

        if (result) {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/questions/${questionID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                alert('Question has been deleted.');
                navigate(-1);
            } else {
                alert('Failed to delete question.');
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditQuestion({ ...editQuestion, [name]: value });
    };

    const handleDifficultyChange = (e) => {
        const difficultyMapping = {
            '하': 3,
            '중': 2,
            '상': 1
        };
        const selectedDifficulty = e.target.value;
        setEditQuestion({ ...editQuestion, difficulty: difficultyMapping[selectedDifficulty] });
        setDifficultyError(!selectedDifficulty);
    };

    const handleContentChange = (index, field, value) => {
        const newContent = [...editQuestion.content];
        newContent[index][field] = value;
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleAddContent = () => {
        setEditQuestion({
            ...editQuestion,
            content: [...editQuestion.content, { type: 'text', value: '' }]
        });
    };

    const handleRemoveContent = (index) => {
        const newContent = [...editQuestion.content];
        newContent.splice(index, 1);
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleOptionChange = (index, field, value) => {
        const newOptions = [...editQuestion.options];
        newOptions[index][field] = value;
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleAddOption = () => {
        setEditQuestion({
            ...editQuestion,
            options: [...editQuestion.options, { type: 'text', name: '', value: '' }]
        });
    };

    const handleRemoveOption = (index) => {
        const newOptions = [...editQuestion.options];
        newOptions.splice(index, 1);
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleTagChange = (event, newValue) => {
        setSelectedTag(newValue);
        if (newValue) {
            fetchDetailedTags(newValue.tagID);
        } else {
            setDetailedTags([]);
            setSelectedDetailedTag(null);
        }
    };

    const handleDetailedTagChange = (event, newValue) => {
        setSelectedDetailedTag(newValue);
    };

    const renderContent = () => {
        if (!Array.isArray(question.content)) {
            return null;
        }
        return question.content.map((item, index) => {
            if (item.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: item.value }} />
                    </div>
                );
            } else if (item.type === 'image') {
                return <img key={index} src={item.value} alt={`content-${index}`} style={{ maxWidth: '100%' }} />;
            } else {
                return null;
            }
        });
    };

    const renderOptions = () => {
        if (!Array.isArray(question.options)) {
            return null;
        }
        return question.options.map((option, index) => {
            if (option.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: `<strong>${option.name}</strong>: ${option.value}` }} />
                    </div>
                );
            } else if (option.type === 'image') {
                return (
                    <div key={index}>
                        <Typography><strong>{option.name}:</strong></Typography>
                        <img src={option.value} alt={`option-${index}`} style={{ maxWidth: '100%' }} />
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    const convertToLocalTime = (utcTime) => {
        if (!utcTime) return 'Unknown'; // 시간 데이터가 없을 경우 처리
        const date = new Date(utcTime);
        return date.toLocaleString(); // 로컬 시간 형식으로 변환
    };

    const difficultyMapping = {
        3: '하',
        2: '중',
        1: '상'
    };

    // 이전 질문으로 이동하는 함수
    const handlePreviousQuestion = () => {
        if (!questions || !question) return;
        const currentIndex = questions.findIndex(q => q.order === question.order);
        if (currentIndex > 0) {
            const previousQuestion = questions[currentIndex - 1];
            navigate(`/admin/quizzes/${quizID}/questions/${previousQuestion.questionID}`, {
                state: { quizID, questionID: previousQuestion.questionID, questions, showImageOnly }
            });
        } else {
            alert("This is the first question.");
        }
    };

    // 다음 질문으로 이동하는 함수
    const handleNextQuestion = () => {
        if (!questions || !question) return;
        const currentIndex = questions.findIndex(q => q.order === question.order);
        if (currentIndex < questions.length - 1) {
            const nextQuestion = questions[currentIndex + 1];
            navigate(`/admin/quizzes/${quizID}/questions/${nextQuestion.questionID}`, {
                state: { quizID, questionID: nextQuestion.questionID, questions, showImageOnly }
            });
        } else {
            alert("This is the last question.");
        }
    };

    if (!question) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <div className='w-full' style={{ position: 'relative', minHeight: '90vh' }}>
            <IconButton onClick={() => onPageChange(`quizzes/${quizID}/questions`, { quizID: quizID, showImageOnly: showImageOnly })} color="primary">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
                Question Details
            </Typography>
            {/* 최종 수정자와 수정 날짜 */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    p: 2,
                    borderBottom: '1px solid #ddd',
                }}
            >
                <Typography variant="body1" color="textSecondary">
                    {question.modifiedBy?.name || 'Unknown'} • {question.lastModifiedAt}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {`ID: ${question.modifiedBy?.userID || 'Unknown'}`}
                </Typography>
            </Box>

            {/* Edit and Delete Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <IconButton color="primary" onClick={handleEditClick}>
                    <EditIcon />
                </IconButton>
                <IconButton color="secondary" onClick={handleDeleteClick}>
                    <DeleteIcon />
                </IconButton>
            </Box>
            {/* 문제 세부 정보 */}
            <Paper
                sx={{
                    p: 2,
                    mb: 2,
                    border: '1px solid gray',
                    height: 'auto',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Box sx={{ flex: 1, pr: 2 }}>
                    <Typography variant="h6">
                        <strong>Order:</strong> {question.order}
                    </Typography>
                    <Typography variant="h6">
                        <strong>Difficulty:</strong> {difficultyMapping[question.difficulty]}
                    </Typography>
                    <Typography variant="h6">
                        <strong>Content:</strong>
                    </Typography>
                    {renderContent()}
                    <Typography variant="h6">
                        <strong>Question Text:</strong> {question.questiontext}
                    </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h6">
                        <strong>Options:</strong>
                    </Typography>
                    {renderOptions()}
                    <Typography variant="h6">
                        <strong>Answer:</strong> {question.answer}
                    </Typography>
                    <Typography variant="h6">
                        <strong>Question Type:</strong> {question.questionType}
                    </Typography>
                    <Typography variant="h6">
                        <strong>Explanation:</strong> {question.explanation}
                    </Typography>
                    <Typography variant="h6">
                        <strong>Tag:</strong> {question.tag?.tagName}
                    </Typography>
                    <Typography variant="h6">
                        <strong>Detailed Tag:</strong> {question.detailedTag?.detailedTagName}
                    </Typography>
                </Box>
            </Paper>
            {/* 메모 입력 및 표시 */}
            <Box sx={{ mt: 2, borderTop: '1px solid #ddd', pt: 2, mx: 2, bgcolor: '#f9f9f9' }}>
                <Typography variant="h6">메모</Typography>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body2" color="textSecondary">
                        {question.modificationNote || '메모가 없습니다.'}
                    </Typography>
                </Box>
            </Box>

            {/* 이전/다음 버튼 */}
            {questions && questions.length > 0 && (
                <Box
                    sx={{
                        position: 'absolute', // 부모 요소 기준으로 배치
                        bottom: 0, // 부모 요소의 하단에 고정
                        left: 0,
                        width: '100%',
                        backgroundColor: '#fff',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button variant="contained" color="primary" onClick={handlePreviousQuestion}>
                        이전
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNextQuestion}>
                        다음
                    </Button>
                </Box>
            )}

            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="lg">
                <DialogTitle>Edit Question</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edit the details of the question.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="order"
                        label="Order"
                        value={editQuestion?.order || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Difficulty</Typography>
                        <Select
                            name="difficulty"
                            value={editQuestion?.difficulty === 3 ? '하' : editQuestion?.difficulty === 2 ? '중' : '상'}
                            onChange={handleDifficultyChange}
                            fullWidth
                            error={difficultyError}
                        >
                            <MenuItem value="상">상</MenuItem>
                            <MenuItem value="중">중</MenuItem>
                            <MenuItem value="하">하</MenuItem>
                        </Select>
                    </Box>
                    <TextField
                        margin="dense"
                        name="questiontext"
                        label="Question Text"
                        value={editQuestion?.questiontext || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />

                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Content</Typography>
                        {editQuestion?.content.map((item, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    mb: 2,
                                    border: '1px solid gray',
                                    p: 1,
                                }}
                            >
                                <Select
                                    value={item.type}
                                    onChange={(e) => handleContentChange(index, 'type', e.target.value)}
                                    sx={{ mb: 1 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {item.type === 'text' ? (
                                    <ReactQuill
                                        value={item.value}
                                        onChange={(content) => handleContentChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ size: [] }, { font: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [
                                                    { list: 'ordered' },
                                                    { list: 'bullet' },
                                                    { indent: '-1' },
                                                    { indent: '+1' },
                                                ],
                                                ['clean'],
                                                [{ formula: 'formula' }],
                                                [{ color: [] }, { background: [] }],
                                            ],
                                            clipboard: { matchVisual: false },
                                        }}
                                        formats={[
                                            'size',
                                            'font',
                                            'bold',
                                            'italic',
                                            'underline',
                                            'strike',
                                            'blockquote',
                                            'list',
                                            'bullet',
                                            'indent',
                                            'color',
                                            'background',
                                            'formula',
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Image URL"
                                            value={item.value}
                                            onChange={(e) => handleContentChange(index, 'value', e.target.value)}
                                            fullWidth
                                            sx={{ mb: 1 }}
                                        />
                                        <Box sx={{ mt: 1 }}>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => setSelectedFile(e.target.files[0])}
                                            />
                                        </Box>
                                        {uploadedFileUrl && (
                                            <Box sx={{ mt: 1 }}>
                                                <Typography variant="body1">
                                                    <strong>Uploaded Image URL:</strong>
                                                </Typography>
                                                <Typography variant="body2">{uploadedFileUrl}</Typography>
                                                <img
                                                    src={uploadedFileUrl}
                                                    alt={`Uploaded content ${index}`}
                                                    style={{ maxWidth: '100%', marginTop: '10px' }}
                                                />
                                            </Box>
                                        )}
                                        <Button
                                            onClick={() => handleUploadClick_content(index)}
                                            color="primary"
                                            variant="contained"
                                            sx={{ mt: 1 }}
                                        >
                                            Upload Content Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveContent(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddContent} color="primary" startIcon={<AddIcon />}>
                            Add Content
                        </Button>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Options</Typography>
                        {editQuestion?.options.map((option, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={option.type}
                                    onChange={(e) => handleOptionChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                <TextField
                                    label="Name"
                                    value={option.name}
                                    onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                                    sx={{ mr: 2 }}
                                />
                                {option.type === 'text' ? (
                                    <ReactQuill
                                        value={option.value}
                                        onChange={(content) => handleOptionChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Image URL"
                                            value={option.value}
                                            onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                                            fullWidth
                                        />
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) => setSelectedFile(e.target.files[0])}
                                        />
                                        <Button onClick={() => handleUploadClick(option.name, index)} color="primary" variant="contained" sx={{ mt: 2 }}>
                                            Upload Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveOption(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddOption} color="primary" startIcon={<AddIcon />}>
                            Add Option
                        </Button>
                    </Box>

                    <TextField
                        margin="dense"
                        name="answer"
                        label="Answer"
                        value={editQuestion?.answer || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="questionType"
                        label="Question Type"
                        value={editQuestion?.questionType || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="explanation"
                        label="Explanation"
                        value={editQuestion?.explanation || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    {/* Memo field */}
                    <TextField
                        margin="dense"
                        name="modificationNote"
                        label="modificationNote"
                        value={editQuestion?.modificationNote || ''}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                        rows={5}
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Tags</Typography>
                        <Autocomplete
                            options={tags}
                            getOptionLabel={(option) => option.tagName}
                            value={selectedTag}
                            onChange={handleTagChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Tag"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Box>
                    {selectedTag && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6">Detailed Tags</Typography>
                            <Autocomplete
                                options={detailedTags}
                                getOptionLabel={(option) => option.detailedTagName}
                                value={selectedDetailedTag}
                                onChange={handleDetailedTagChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Detailed Tag"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClick} color="primary" disabled={!editQuestion?.difficulty}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default QuizQuestionDetailsPage;