import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, MenuItem, Select
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../../contexts/Apicontext';

const ClassLecturesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { classID, className } = location.state || {};
  const [lectures, setLectures] = useState([]);
  const [existingLectures, setExistingLectures] = useState([]);
  const [filteredLectures, setFilteredLectures] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [editLectureId, setEditLectureId] = useState(null);
  const [editLecture, setEditLecture] = useState({ title: '', videoURL: '', resources: '' });
  const [newLecture, setNewLecture] = useState({ title: '', videoURL: '', resources: '' });
  const [selectedLectureId, setSelectedLectureId] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openSelectDialog, setOpenSelectDialog] = useState(false);
  const apiBaseUrl = useApi();

  useEffect(() => {
    fetchLectures();
    fetchExistingLectures();
  }, [classID]);

  const fetchLectures = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${apiBaseUrl}/admin/classes/${classID}/lectures`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    console.log(data);
    setLectures(data);
  };

  const fetchExistingLectures = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${apiBaseUrl}/admin/lectures`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    setExistingLectures(data);
    setFilteredLectures(data);
  };

  const handleEditClick = (lecture) => {
    setEditLectureId(lecture.lectureID);
    setEditLecture({ title: lecture.title, videoURL: lecture.videoURL, resources: lecture.resources });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setEditLectureId(null);
  };

  const handleSaveClick = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${apiBaseUrl}/admin/lectures/${editLectureId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(editLecture)
    });

    if (response.ok) {
      alert('Saved! Lecture information has been updated.');
      handleCloseEditDialog();
      fetchLectures();
    } else {
      alert('Error! Failed to update lecture information.');
    }
  };

  const handleDeleteClick = async (lectureId, event) => {
    event.stopPropagation();
    const result = window.confirm('Are you sure you want to delete this lecture?');

    if (result) {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/admin/classes/${classID}/lectures/${lectureId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        alert('Deleted! Lecture has been deleted.');
        fetchLectures();
      } else {
        alert('Error! Failed to delete lecture.');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditLecture({ ...editLecture, [name]: value });
  };

  const handleNewLectureInputChange = (e) => {
    const { name, value } = e.target;
    setNewLecture({ ...newLecture, [name]: value });
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setNewLecture({ title: '', videoURL: '', resources: '' });
  };

  const handleAddLecture = async () => {
    if (!newLecture.title || !newLecture.videoURL || !newLecture.resources) {
      alert('Please fill out all fields for the new lecture.');
      return;
    }

    const token = localStorage.getItem('token');
    const response = await fetch(`${apiBaseUrl}/admin/classes/${classID}/lectures`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(newLecture)
    });

    if (response.ok) {
      alert('Added! New lecture has been added.');
      handleCloseAddDialog();
      fetchLectures();
    } else {
      alert('Error! Failed to add new lecture.');
    }
  };

  const handleOpenSelectDialog = () => {
    setOpenSelectDialog(true);
  };

  const handleCloseSelectDialog = () => {
    setOpenSelectDialog(false);
    setSelectedLectureId('');
  };

  const handleAddExistingLecture = async () => {
    if (!selectedLectureId) {
      alert('Please select a lecture first.');
      return;
    }

    const token = localStorage.getItem('token');
    const response = await fetch(`${apiBaseUrl}/admin/classes/${classID}/lectures`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ lectureID: selectedLectureId })
    });

    if (response.ok) {
      alert('Added! Existing lecture has been added to the class.');
      handleCloseSelectDialog();
      fetchLectures();
    } else {
      alert('Error! Failed to add existing lecture.');
    }
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    if (value) {
      const filtered = existingLectures.filter(lecture =>
        lecture.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredLectures(filtered);
    } else {
      setFilteredLectures(existingLectures);
    }
  };

  // 각 강의 행을 클릭하면 해당 강의의 lectureID를 quizID로 사용하여 /admin/quizzes/{quizID}/questions로 이동
  const handleLectureClick = (lectureID) => {
    navigate(`/admin/quizzes/${lectureID}/questions`, { state: { quizID: lectureID } });
  };

  return (
    <div className='w-full'>
      <IconButton onClick={() => navigate(-1)} color="primary">
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h5" gutterBottom>
        Lectures of {className}
      </Typography>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddDialog} sx={{ mb: 2 }}>
        Add New Lecture
      </Button>
      <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={handleOpenSelectDialog} sx={{ mb: 2, ml: 2 }}>
        Add Existing Lecture
      </Button>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Video URL</TableCell>
              <TableCell>Resources</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lectures.map((lecture) => (
              <TableRow key={lecture.lectureID} hover sx={{ cursor: 'pointer' }} onClick={() => handleLectureClick(lecture.lectureID)}>
                <TableCell>{lecture.title}</TableCell>
                <TableCell>{lecture.videoURL}</TableCell>
                <TableCell>{lecture.resources}</TableCell>
                <TableCell>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleEditClick(lecture); }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteClick(lecture.lectureID, e); }}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add New Lecture Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
        <DialogTitle>Add New Lecture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the details of the new lecture.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            value={newLecture.title}
            onChange={handleNewLectureInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            name="videoURL"
            label="Video URL"
            value={newLecture.videoURL}
            onChange={handleNewLectureInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            name="resources"
            label="Resources"
            value={newLecture.resources}
            onChange={handleNewLectureInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddLecture} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Lecture Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
        <DialogTitle>Edit Lecture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit the details of the lecture.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            value={editLecture.title}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            name="videoURL"
            label="Video URL"
            value={editLecture.videoURL}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            name="resources"
            label="Resources"
            value={editLecture.resources}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Select Existing Lecture Dialog */}
      <Dialog open={openSelectDialog} onClose={handleCloseSelectDialog} fullWidth maxWidth="sm">
        <DialogTitle>Select Existing Lecture</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose an existing lecture to add to this class.
          </DialogContentText>
          <TextField
            margin="dense"
            label="Search Lectures"
            value={searchText}
            onChange={handleSearchChange}
            fullWidth
          />
          <Select
            name="existingLecture"
            value={selectedLectureId}
            onChange={(e) => setSelectedLectureId(e.target.value)}
            fullWidth
          >
            {filteredLectures.map((lecture) => (
              <MenuItem key={lecture.lectureID} value={lecture.lectureID}>
                {lecture.title}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSelectDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddExistingLecture} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClassLecturesPage;