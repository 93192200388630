import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, TablePagination, Select, MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { useApi } from '../../contexts/Apicontext';
import { useLocation, useNavigate } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync'; // 동기화 아이콘 추가


const QuizzesTable = ({ onPageChange }) => {
    const location = useLocation();
    const [quizzes, setQuizzes] = useState([]);
    const [editQuizId, setEditQuizId] = useState(null);
    const [editQuiz, setEditQuiz] = useState({ title: '', description: '', category: '' });
    const [newQuiz, setNewQuiz] = useState({ title: '', description: '', category: '' });
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const apiBaseUrl = useApi();

    // 페이지네이션 관련 상태
    const [page, setPage] = useState(location.state?.page || 0);
    const [rowsPerPage, setRowsPerPage] = useState(location.state?.rowsPerPage || 10);

    useEffect(() => {
        fetchQuizzes();

        // `popstate` 이벤트 리스너 추가
        window.onpopstate = (event) => {
            if (event.state) {
                setPage(event.state.page);
                setRowsPerPage(event.state.rowsPerPage);
            }
        };
    }, []);

    useEffect(() => {
        // 현재 페이지네이션 상태를 히스토리에 저장
        window.history.replaceState({ page, rowsPerPage }, '', '');
    }, [page, rowsPerPage]);

    const fetchQuizzes = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizzes`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setQuizzes(data);
    };

    const handleEditClick = (quiz) => {
        setEditQuizId(quiz.quizID);
        setEditQuiz({ title: quiz.title, description: quiz.description, category: quiz.category });
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setEditQuizId(null);
    };

    const handleSaveClick = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/quizzes/${editQuizId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editQuiz)
        });

        if (response.ok) {
            alert('Saved! Quiz information has been updated.');
            handleCloseEditDialog();
            fetchQuizzes();
        } else {
            alert('Error! Failed to update quiz information.');
        }
    };

    const handleDeleteClick = async (quizId) => {
        const result = window.confirm('Are you sure you want to delete this quiz?');

        if (result) {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/quizzes/${quizId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                alert('Deleted! Quiz has been deleted.');
                fetchQuizzes();
            } else {
                alert('Error! Failed to delete quiz.');
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditQuiz({ ...editQuiz, [name]: value });
    };

    const handleNewQuizInputChange = (e) => {
        const { name, value } = e.target;
        setNewQuiz({ ...newQuiz, [name]: value });
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
        setNewQuiz({ title: '', description: '', category: '' });
    };

    const handleAddQuiz = async () => {
        if (!newQuiz.title || !newQuiz.description || !newQuiz.category) {
            alert('Please fill out all fields for the new quiz.');
            return;
        }

        const token = localStorage.getItem('token');

        const response = await fetch(`${apiBaseUrl}/admin/quizzes`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newQuiz)
        });

        if (response.ok) {
            alert('Added! New quiz has been added.');
            handleCloseAddDialog();
            fetchQuizzes();
        } else {
            alert('Error! Failed to add new quiz.');
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0); // 페이지 변경 시 첫 페이지로 이동
    };

    return (
        <div className='w-full'>
            <Typography variant="h4" gutterBottom>
                All Quizzes
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddDialog}>
                    Add New Quiz
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Quiz ID</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quizzes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((quiz) => (
                            <TableRow key={quiz.quizID}>
                                <TableCell>{quiz.quizID}</TableCell>
                                <TableCell>{quiz.title}</TableCell>
                                <TableCell>{quiz.description}</TableCell>
                                <TableCell>{quiz.category}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditClick(quiz)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(quiz.quizID)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <Button variant="outlined" onClick={() => onPageChange(`quizzes/${quiz.quizID}/questions`, { quizID: quiz.quizID, page: page, rowsPerPage: rowsPerPage })} sx={{ mx: 1 }}>
                                        Questions
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={quizzes.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
                <DialogTitle>Edit Quiz</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edit the details of the quiz.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label="Title"
                        value={editQuiz.title}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="description"
                        label="Description"
                        value={editQuiz.description}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Select
                        margin="dense"
                        name="category"
                        value={editQuiz.category}
                        onChange={handleInputChange}
                        fullWidth
                    >
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Math">Math</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClick} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Dialog */}
            <Dialog open={openAddDialog} onClose={handleCloseAddDialog} fullWidth maxWidth="sm">
                <DialogTitle>Add New Quiz</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the details of the new quiz.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label="Title"
                        value={newQuiz.title}
                        onChange={handleNewQuizInputChange}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        name="description"
                        label="Description"
                        value={newQuiz.description}
                        onChange={handleNewQuizInputChange}
                        fullWidth
                    />
                    <Select
                        margin="dense"
                        name="category"
                        value={newQuiz.category}
                        onChange={handleNewQuizInputChange}
                        fullWidth
                    >
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="Math">Math</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddQuiz} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default QuizzesTable;
